import { ClientBase } from "./ClientBase.ts";
  
export class Client extends ClientBase {
        private http: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };
        private baseUrl: string;
        protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
    
        constructor(baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) {
            super()
            this.http = http ? http : window as any;
            this.baseUrl = baseUrl ?? "";
        }
    
        /**
         * @param files (optional) 
         * @param docSection (optional) 
         * @return Success
         */
        documentPOST(files: FileParameter[] | undefined, docSection: string | undefined): Promise<void> {
            let url_ = this.baseUrl + "/api/Document";
            url_ = url_.replace(/[?&]$/, "");
    
            const content_ = new FormData();
            if (files === null || files === undefined)
                throw new Error("The parameter 'files' cannot be null.");
            else
                files.forEach(item_ => content_.append("Files", item_.data, item_.fileName ? item_.fileName : "Files") );
            if (docSection === null || docSection === undefined)
                throw new Error("The parameter 'docSection' cannot be null.");
            else
                content_.append("docSection", docSection.toString());
    
            let options_: RequestInit = {
                body: content_,
                method: "POST",
                headers: {
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processDocumentPOST(_response);
            });
        }
    
        protected processDocumentPOST(response: Response): Promise<void> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                return;
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<void>(null as any);
        }
    
        /**
         * @return Success
         */
        documentGET(): Promise<DocumentsList> {
            let url_ = this.baseUrl + "/api/Document";
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: RequestInit = {
                method: "GET",
                headers: {
                    "Accept": "application/json"
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processDocumentGET(_response);
            });
        }
    
        protected processDocumentGET(response: Response): Promise<DocumentsList> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                let result200: any = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as DocumentsList;
                return result200;
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<DocumentsList>(null as any);
        }
    
        /**
         * @return Success
         */
        documentGET2(docName: string): Promise<DocumentVm> {
            let url_ = this.baseUrl + "/api/Document/{docName}";
            if (docName === undefined || docName === null)
                throw new Error("The parameter 'docName' must be defined.");
            url_ = url_.replace("{docName}", encodeURIComponent("" + docName));
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: RequestInit = {
                method: "GET",
                headers: {
                    "Accept": "application/json"
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processDocumentGET2(_response);
            });
        }
    
        protected processDocumentGET2(response: Response): Promise<DocumentVm> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                let result200: any = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as DocumentVm;
                return result200;
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<DocumentVm>(null as any);
        }
    
        /**
         * Добавляет нового члена СНТ в базу данных
         * @param body (optional) 
         * @return Success
         */
        memberPOST(body: CreateMemberDto | undefined): Promise<string> {
            let url_ = this.baseUrl + "/api/Member";
            url_ = url_.replace(/[?&]$/, "");
    
            const content_ = JSON.stringify(body);
    
            let options_: RequestInit = {
                body: content_,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processMemberPOST(_response);
            });
        }
    
        protected processMemberPOST(response: Response): Promise<string> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                let result200: any = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as string;
                return result200;
                });
            } else if (status === 401) {
                return response.text().then((_responseText) => {
                return throwException("Unauthorized", status, _responseText, _headers);
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<string>(null as any);
        }
    
        /**
         * Возврат полного списка членов
         * @return Success
         */
        memberGET(): Promise<MemberList> {
            let url_ = this.baseUrl + "/api/Member";
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: RequestInit = {
                method: "GET",
                headers: {
                    "Accept": "application/json"
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processMemberGET(_response);
            });
        }
    
        protected processMemberGET(response: Response): Promise<MemberList> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                let result200: any = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as MemberList;
                return result200;
                });
            } else if (status === 401) {
                return response.text().then((_responseText) => {
                let result401: any = null;
                result401 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as ProblemDetails;
                return throwException("Unauthorized", status, _responseText, _headers, result401);
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<MemberList>(null as any);
        }
    
        /**
         * Поиск члена СНТ по его имени
         * @param lastName (optional) 
         * @return Success
         */
        memberGET2(firstName: string, lastName: string | undefined): Promise<MemberVm> {
            let url_ = this.baseUrl + "/api/Member/{firstName}?";
            if (firstName === undefined || firstName === null)
                throw new Error("The parameter 'firstName' must be defined.");
            url_ = url_.replace("{firstName}", encodeURIComponent("" + firstName));
            if (lastName === null)
                throw new Error("The parameter 'lastName' cannot be null.");
            else if (lastName !== undefined)
                url_ += "lastName=" + encodeURIComponent("" + lastName) + "&";
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: RequestInit = {
                method: "GET",
                headers: {
                    "Accept": "application/json"
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processMemberGET2(_response);
            });
        }
    
        protected processMemberGET2(response: Response): Promise<MemberVm> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                let result200: any = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as MemberVm;
                return result200;
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<MemberVm>(null as any);
        }
    
        /**
         * Поиск члена снт по номеру его дома
         * @return Success
         */
        memberGET3(propertyNum: number): Promise<MemberVm> {
            let url_ = this.baseUrl + "/api/Member/{propertyNum}";
            if (propertyNum === undefined || propertyNum === null)
                throw new Error("The parameter 'propertyNum' must be defined.");
            url_ = url_.replace("{propertyNum}", encodeURIComponent("" + propertyNum));
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: RequestInit = {
                method: "GET",
                headers: {
                    "Accept": "application/json"
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processMemberGET3(_response);
            });
        }
    
        protected processMemberGET3(response: Response): Promise<MemberVm> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                let result200: any = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as MemberVm;
                return result200;
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<MemberVm>(null as any);
        }
    
        /**
         * Cоздает экземпляр объявления
         * @param title (optional) 
         * @param text (optional) 
         * @param priority (optional) 
         * @param files (optional) 
         * @param images (optional) 
         * @return Success
         */
        notificationPOST(title: string | undefined, text: string | undefined, priority: number | undefined, files: FileParameter[] | undefined, images: FileParameter[] | undefined): Promise<void> {
            let url_ = this.baseUrl + "/api/Notification";
            url_ = url_.replace(/[?&]$/, "");
    
            const content_ = new FormData();
            if (title === null || title === undefined)
                throw new Error("The parameter 'title' cannot be null.");
            else
                content_.append("title", title.toString());
            if (text === null || text === undefined)
                throw new Error("The parameter 'text' cannot be null.");
            else
                content_.append("text", text.toString());
            if (priority === null || priority === undefined)
                throw new Error("The parameter 'priority' cannot be null.");
            else
                content_.append("priority", priority.toString());
            if (files === null || files === undefined)
                throw new Error("The parameter 'files' cannot be null.");
            else
                files.forEach(item_ => content_.append("Files", item_.data, item_.fileName ? item_.fileName : "Files") );
            if (images === null || images === undefined)
                throw new Error("The parameter 'images' cannot be null.");
            else
                images.forEach(item_ => content_.append("Images", item_.data, item_.fileName ? item_.fileName : "Images") );
    
            let options_: RequestInit = {
                body: content_,
                method: "POST",
                headers: {
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processNotificationPOST(_response);
            });
        }
    
        protected processNotificationPOST(response: Response): Promise<void> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                return;
                });
            } else if (status === 401) {
                return response.text().then((_responseText) => {
                return throwException("Unauthorized", status, _responseText, _headers);
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<void>(null as any);
        }
    
        /**
         * @param num (optional) 
         * @return Success
         */
        notificationGET(num: number | undefined): Promise<NotificationList> {
            let url_ = this.baseUrl + "/api/Notification?";
            if (num === null)
                throw new Error("The parameter 'num' cannot be null.");
            else if (num !== undefined)
                url_ += "num=" + encodeURIComponent("" + num) + "&";
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: RequestInit = {
                method: "GET",
                headers: {
                    "Accept": "application/json"
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processNotificationGET(_response);
            });
        }
    
        protected processNotificationGET(response: Response): Promise<NotificationList> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                let result200: any = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as NotificationList;
                return result200;
                });
            } else if (status === 401) {
                return response.text().then((_responseText) => {
                let result401: any = null;
                result401 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as ProblemDetails;
                return throwException("Unauthorized", status, _responseText, _headers, result401);
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<NotificationList>(null as any);
        }
    
        /**
         * @param title (optional) 
         * @return Success
         */
        notificationPUT(title: string | undefined): Promise<void> {
            let url_ = this.baseUrl + "/api/Notification?";
            if (title === null)
                throw new Error("The parameter 'title' cannot be null.");
            else if (title !== undefined)
                url_ += "title=" + encodeURIComponent("" + title) + "&";
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: RequestInit = {
                method: "PUT",
                headers: {
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processNotificationPUT(_response);
            });
        }
    
        protected processNotificationPUT(response: Response): Promise<void> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                return;
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<void>(null as any);
        }
    
        /**
         * @param title (optional) 
         * @return Success
         */
        notificationDELETE(title: string | undefined): Promise<void> {
            let url_ = this.baseUrl + "/api/Notification?";
            if (title === null)
                throw new Error("The parameter 'title' cannot be null.");
            else if (title !== undefined)
                url_ += "title=" + encodeURIComponent("" + title) + "&";
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: RequestInit = {
                method: "DELETE",
                headers: {
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processNotificationDELETE(_response);
            });
        }
    
        protected processNotificationDELETE(response: Response): Promise<void> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                return;
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<void>(null as any);
        }
    
        /**
         * @return Success
         */
        notificationGET2(noteTitle: string): Promise<NotificationVm> {
            let url_ = this.baseUrl + "/api/Notification/{noteTitle}";
            if (noteTitle === undefined || noteTitle === null)
                throw new Error("The parameter 'noteTitle' must be defined.");
            url_ = url_.replace("{noteTitle}", encodeURIComponent("" + noteTitle));
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: RequestInit = {
                method: "GET",
                headers: {
                    "Accept": "application/json"
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processNotificationGET2(_response);
            });
        }
    
        protected processNotificationGET2(response: Response): Promise<NotificationVm> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                let result200: any = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as NotificationVm;
                return result200;
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<NotificationVm>(null as any);
        }
    
        /**
         * @param imageName (optional) 
         * @return Success
         */
        notificationGET3(noteId: string, imageName: string | undefined): Promise<ImagePresenter> {
            let url_ = this.baseUrl + "/api/Notification/{noteId}?";
            if (noteId === undefined || noteId === null)
                throw new Error("The parameter 'noteId' must be defined.");
            url_ = url_.replace("{noteId}", encodeURIComponent("" + noteId));
            if (imageName === null)
                throw new Error("The parameter 'imageName' cannot be null.");
            else if (imageName !== undefined)
                url_ += "imageName=" + encodeURIComponent("" + imageName) + "&";
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: RequestInit = {
                method: "GET",
                headers: {
                    "Accept": "application/json"
                }
            };
    
            return this.http.fetch(url_, options_).then((_response: Response) => {
                return this.processNotificationGET3(_response);
            });
        }
    
        protected processNotificationGET3(response: Response): Promise<ImagePresenter> {
            const status = response.status;
            let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
            if (status === 200) {
                return response.text().then((_responseText) => {
                let result200: any = null;
                result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as ImagePresenter;
                return result200;
                });
            } else if (status === 401) {
                return response.text().then((_responseText) => {
                let result401: any = null;
                result401 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as ProblemDetails;
                return throwException("Unauthorized", status, _responseText, _headers, result401);
                });
            } else if (status !== 200 && status !== 204) {
                return response.text().then((_responseText) => {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
                });
            }
            return Promise.resolve<ImagePresenter>(null as any);
        }
    }

    export default Client;
    
    export interface CreateMemberDto {
        firstName?: string | undefined;
        lastName?: string | undefined;
        propertyNum?: number;
        propertySize?: number;
    }
    
    export interface DocumentVm {
        data?: string | undefined;
    }
    
    export interface DocumentsList {
        docs?: DocPlacer[] | undefined;
    }
    
    export interface ImagePresenter {
        name?: string | undefined;
        data?: string | undefined;
    }
    
    export enum MemberList {
        _0 = 0,
        _1 = 1,
        _2 = 2,
    }
    
    export interface MemberVm {
        memberId?: string;
        firstName?: string | undefined;
        lastName?: string | undefined;
        propertyNum?: number;
        propertySize?: number;
    }
    
    export interface NotificationCutVm {
        title?: string | undefined;
        priority?: number;
        dateTime?: Date;
    }
    
    export interface NotificationList {
        notificationCutVms?: NotificationCutVm[] | undefined;
    }
    
    export interface NotificationVm {
        noteId?: string;
        authorName?: string | undefined;
        title?: string | undefined;
        text?: string | undefined;
        dateTime?: Date;
        documentNames?: string[] | undefined;
        images?: imagePresenter[] | undefined;
    }
    
    export interface ProblemDetails {
        type?: string | undefined;
        title?: string | undefined;
        status?: number | undefined;
        detail?: string | undefined;
        instance?: string | undefined;
    
        [key: string]: any;
    }
    
    export interface DocPlacer {
        docName?: string | undefined;
        docSection?: string | undefined;
    }
    
    export interface imagePresenter {
        name?: string | undefined;
        noteParent?: string;
    }
    
    export interface FileParameter {
        data: any;
        fileName: string;
    }
    
    export class ApiException extends Error {
        message: string;
        status: number;
        response: string;
        headers: { [key: string]: any; };
        result: any;
    
        constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
            super();
    
            this.message = message;
            this.status = status;
            this.response = response;
            this.headers = headers;
            this.result = result;
        }
    
        protected isApiException = true;
    
        static isApiException(obj: any): obj is ApiException {
            return obj.isApiException === true;
        }
    }
    
    function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
        if (result !== null && result !== undefined)
            throw result;
        else
            throw new ApiException(message, status, response, headers, null);
    }