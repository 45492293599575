import React, { useState } from 'react'
import elDebts from './elDebts'

export default function Debbts() {
    
    let [tblOpen, setTableOpen] = useState(false)
    let [tblOpen1, setTableOpen1] = useState(false)
    
    const inDebts = 
         [
            {
                propNum: '№25',
                check: "",
                debPeriods: ['2023 ноя., дек.'],
                periodDebts: [2000, ],
                fullDebt: 2000
            },
            {
                propNum: '№28',
                check: 'Данные не поданы',
                debPeriods: ['2019 3,4кв.', '2020 1,2,3,4кв.', '2021 1,2,3,4кв.', '2022 1,2,3,4кв', '2023 1,2,3,4 кв', '2024 1,2кв'],
                periodDebts: [6000, 12000, 12000, 12000, 12000, 6000],
                fullDebt: 60000
            },
            {
                propNum: '№30',
                check: "Данные не поданы",
                debPeriods: ['2020 1,2,3,4кв', '2021 1,2,3,4кв', '2022 1,2,3,4 кв', '2023 1,2,3,4 кв', '2024 1,2 кв'],
                periodDebts: [12000, 12000, 12000, 12000, 6000],
                fullDebt: 54000
            },
            {
                propNum: '№34',
                check: '',
                debPeriods: ['2019 3,4кв.', '2020 1,2,3,4кв.', '2021 1,2,3,4кв.', '2022 1,2,3,4кв.', '2023 1,2,3,4 кв', '2024 1,2 кв'],
                periodDebts: [6000, 12000, 12000, 12000, 12000, 6000],
                fullDebt: 60000
            },
            {
                propNum: '№36',
                check: '',
                debPeriods: ['2023 1,2,3,4 кв.', '2024 1,2 кв'],
                periodDebts: [12000, 6000],
                fullDebt: 18000
            },
            {
                propNum: '№44',
                check: '',
                debPeriods: ['2021 3,4кв.', '2022 1,2,3,4кв.', '2023 1,2,3,4 кв', '2024 1,2 кв.'],
                periodDebts: [6000, 12000, 12000, 6000],
                fullDebt: 36000
            },
            {
                propNum: '№45',
                check: '',
                debPeriods: ['2020 3,4кв.', '2021 1,2,3,4кв.', '2022 1,2,3,4кв.', '2023 1,2,3,4 кв'],
                periodDebts: [6000, 12000, 12000, 12000],
                fullDebt: 42000
            },
            {
                propNum: '№53',
                check: "Данные не поданы",
                debPeriods: ['2018 4кв.', '2019 1,2,3,4кв.', '2020 1,2,3,4кв.', '2021 1,2,3,4кв.', '2022 1,2,3,4кв.', '2023 1,2,3,4 кв.', '2024 1,2 кв'],
                periodDebts: [2100, 10200, 12000, 12000, 12000, 12000, 6000],
                fullDebt: 66300
            },
            {
                propNum: '№59',
                check: '',
                debPeriods: ['2019 2,3,4кв', '2020 1,2,3,4кв', '2021 1,2,3,4кв', '2022 1,2,3,4 кв', '2023 1,2,3,4 кв', '2024 1,2 кв.'],
                periodDebts: [8100, 12000, 12000, 12000, 12000, 6000],
                fullDebt: 62100
            },
            {
                propNum: '№73',
                check: "Собственник не определен",
                debPeriods: ['Нет оплат с 2001 г.'],
                periodDebts: [],
                fullDebt: ''
            },
            {
                propNum: '№ 74',
                check: "Под арестом",
                debPeriods: ['Нет оплат с 2013 г.'],
                periodDebts: [],
                fullDebt: ''
            },
            {
                propNum: '№74а',
                check: '',
                debPeriods: ['2015 3,4 кв', '2016 1,2,3,4 кв', '2017 1,2,3,4кв', '2018 1,2,3,4кв', '2019 1,2,3,4кв', '2020 1,2,3,4кв', '2021 1,2,3,4кв', '2022 1,2,3,4кв', '2023 1,2 кв.', '2023 август'],
                periodDebts: [3480, 7680, 8400, 8400, 10200, 12000, 12000, 12000, 6000, 1000, ],
                fullDebt: 81160
            },
            {
                propNum: '№76 ',
                check: '',
                debPeriods: ['2023 1,2 кв.', ' 2023 июль', '2023 август'],
                periodDebts: [6000, 1000, 1000],
                fullDebt: 8000
            },
            {
                propNum: '№79',
                check: '',
                debPeriods: ['2019 1,2,3,4 кв', '2020 1,2,3,4 кв', '2021 1,2,3,4 кв', '2022 1,2,3,4 кв', '2023 1,2,3,4 кв', '2024 1,2 кв.'],
                periodDebts: [10200, 12000, 12000, 12000, 12000, 6000],
                fullDebt: 64200
            }
        ]

  return (
    <div debtsNote className>
    <div className='debtsText'><p>Уважаемые садоводы.</p>
<p>Проверьте Ваши оплаты, в случае наличия неучтенных оплат свяжитесь с секретарем Правления для сверки данных.</p>
</div>

    <div className='unwrapper' onClick={() => setTableOpen(tblOpen = !tblOpen)}> Просмотреть полный список задолженностей по членским взносам</div>
        {tblOpen && (
                <div>
                <div className='DebtsTable'>
                <div className='tblHeader'><font color="red">Список задолженностей на 15.09.2024 гг.</font></div>
                <div className='tblRows'>
                    <div>Номер участка</div>
                    <div>Период задолженности</div>
                    <div>Сумма задолженности по периодам</div>
                    <div>Общая сумма задолженности</div>
                </div>
            {inDebts.map(el => 
            (
                <div className='singleRow'>
                    <div className='firstCol'>
                        <div>{el.propNum}</div>
                        <div className='checkProp'>{el.check}</div>
                    </div>
                    <div className='periods'>
                    {el.debPeriods.map(els =>
                        (
                            <div >{els}</div>
                        )
                        )}
                    </div>
                    <div className='periodDebs'>
                    {el.periodDebts.map(els =>
                        (
                            <div>{els}</div>
                        )
                        )}
                    </div>
                    <div className='fullDebt'>{el.fullDebt}</div>
                </div>
            ))}
            </div>
            </div>)}

            <div className='debtsText'>
                <p>Мы подаем показания общего счетчика СНТ <font color='red'>ежемесячно</font> в МОСЭНЭРГО и, соответственно, оплачиваем выставленные нам счета <font color='red'>ежемесячно</font>, иначе нам просто отключат электроэнергию (не смотря ни на какие прямые договора).</p>
                <p>В таблице дана информация о датах последних внесенных собственниками оплат за потребленное электричество (на 15.05.2024 г.)</p>
                <p>Из каких средств СНТ должен оплачивать каждый месяц разницу между оплаченной и потребленной собственниками электроэнергией?</p>
</div>

            <div className='unwrapper' onClick={() => setTableOpen1(tblOpen1 = !tblOpen1)}> Просмотреть полный список задолженностей по оплатам за электроснабжение</div>
        {tblOpen1 && (
                <div>
                <div className='DebtsTable'>
                <div className='tblHeader'><font color="red">Оплата электричества СНТ «Солнечное» на 15.05.2024г.</font></div>
                <div className='tblRows'>
                    <div>Номер участка</div>
                    <div>Дата последней оплаты</div>
                    <div>Долг по оплате электричества из-за ошибки в расчетах </div>
                    <div>Примечания</div>
                </div>
            {elDebts.map((el, index) => 
            (
                <div className='singleRow'>
                    <div className='firstCol' >
                        <font color={el.clrCode}>{!el.propNum ? <div>{index+1}</div> : <div>{el.propNum}</div>} </font>
                    </div>
                    <div className='periods'>
                    <font color={el.clrCode}>{el.lastPay}</font>
                    </div>
                    <div className='periodDebs'>
                    <font color={el.clrCode}>{el.debt}</font>
                    </div>
                    <div className='trivia' > <font color={el.clrCode}>{el.trivia}</font></div>

                </div>
            ))}
            </div>
                        <div className='debtsText'>Участки без предоставления данных о собственности, неплательщики, заброшенные: 28,30,53,59,73,74,79. Такие участки подлежат изъятию согласно законодательству РФ (ст. 285 ГК РФ) как использующиеся не по целевому назначению . Запрос от Администрации района о наличии таких участков был, <font color="red">повторно</font>, <u>пока</u> данные не подавались.
Согласно документам собственник участка 73 не определен, а участок 74 под арестом.
    </div> 
            </div>)}


            <div className='debtsText'>
            <p><span style={{ 'color': '#ff0000' }}><strong><span style={{ 'text-decoration': 'underline' }}>Задолженности только за 2024 год</span></strong></span></p>
<p><strong>Задолженности:</strong></p>
<p><strong>За <span style={{ 'color': '#ff0000' }}>1 кв.2024</span> г: &nbsp;33,50,54,85. </strong></p>
<p><strong>За<span style={{ 'color': '#ff0000' }}> февраль, март 2024 г</span>.:&nbsp; 64</strong></p>
<p><strong>За <span style={{ 'color': '#ff0000' }}>2 кв.2024 г</span>: 15, 33, 50, 54, 64, 85</strong></p>

<p><strong><span style={{ 'text-decoration': 'underline' }}>ЗАДОЛЖНОСТИ ПО ИСПОЛНИТЕЛЬНЫМ ЛИСТАМ</span></strong></p>

<p><strong>&nbsp;&nbsp;по кассационному суду:&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong><span style={{ 'color': '#ff0000' }}><strong><span style={{ 'text-decoration': 'underline' }}>Участки&nbsp; 20, 74а, 76</span></strong></span></p>

<p><strong><span style={{ 'text-decoration': 'underline' }}>Задолженности по целевым взносам, установленным решением общего собрания за &nbsp;вынос счетчиков , объединенных в систему АСКУЭ:</span></strong></p>

<p><strong>Нет доплаты целевого взноса, в размере 2000р. (счетчики установлены) : 1,2,4,8,10,18,21,32,37,38, 39,40,48,71,80,81</strong></p>

<p><strong>Нет доплаты целевого взноса, в размере 2000р. (счетчики не установлены, установка&nbsp; вторым потоком ):</strong></p>
<p><strong>12,14, 25, 34,44,45,46,50,62,72&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong></p>

<p><strong>Нет оплаты установленного общим собранием целевого взноса в размере 10&nbsp;000 р.:</strong></p>
<p><strong>6,19,22,26,28,30,42,53,55,59,67,73,74,79,82,84,85,74а&nbsp;&nbsp;&nbsp;&nbsp; </strong></p>

<p><strong>Примечания.</strong></p>

<p><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Участки без предоставления данных о собственности, неплательщики, заброшенные: <span style={{ 'color': '#ff0000' }}>28,30,53,59,73,74,79. Такие участки</span> подлежат изъятию согласно законодательству РФ (ст. 285 ГК РФ) как использующиеся не по целевому назначению. Запрос от Администрации района о наличии таких участков был,&nbsp;<span style={{ 'color': '#ff0000' }}>повторно</span>,&nbsp;<span style={{ 'text-decoration': 'underline' }}>пока</span> данные не подавались.</strong></p>
<p><strong>Согласно документам собственник участка 73 не определен, а участок 74 под арестом.</strong></p>

<p><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; На собрании председателей СНТ 18 мая Администрация обратилась с напоминанием о необходимости всем собственникам зарегистрировать в Росреестре свои дома. Хотят провести тотальную проверку.</strong></p>
</div>
    </div>
  )
}


//<div className='headerOfDebts'>Задолженности по оплате</div>