import React from 'react'
import { Client} from '../Api/Api.ts'
import PropTypes from "prop-types";

import { Outlet, Link } from "react-router-dom";


export default function LatestNotifications({apiClient, notifs}) {
  
  

  return (
    <div className='latestNotif'>

        <div className='previewList'>
          {notifs.map((el, ind) => (
            <div className='insideNote'>
              <div>{ind+1}.</div>
              <Link className='myLi' to={el.title}>{el.priority > 0  ? 
              <font color='red'>{el.title}</font> : el.title}</Link>                       
          </div>
          ))}
        </div>
    <Outlet />
    </div>
  )
}


/*LatestNotifications.propTypes = {
  noteName: PropTypes.string,
  notifs: [] ,
  apiClient: Client
};   */   

//<div className='notifHeader'>Последние объявления</div>